.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    padding: 0;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
    &:hover:before {
        color: $black;
    }
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
    &:hover:before {
        color: $black;
    }
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: 0;
    display: block;
    width: auto;
    padding: 0;
    list-style: none;
    z-index: 3;
    left: 47px;
    text-align: left;
    li {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 1px;
        padding: 0;
        cursor: pointer;
        button {
            font-size: 0;
            line-height: 0;  
            display: block; 
            width: 20px;
            height: 20px;
            padding: 5px;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: none;
            background: transparent;
            &:hover,
            &:focus
            {
                outline: none;
            }
            &:hover:before,
            &:focus:before
            {
                opacity: 1;
            }
            &:before
            {
                font-family: 'slick';
                /* font-size: 40px; */
                line-height: 20px;
                border-radius: 50%;
                position: absolute;
                top: 0;
                left: 0;
                width: 13px;
                height: 13px;
                content: "•";
                text-align: center;
                opacity: 1;
                color: #fff;
                border: 2px solid #fff;
                background-color: white;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
    }
}

.slick-dots li.slick-active button:before
{
    opacity: .75;
    background-color: transparent;
}

.home-slider {
    overflow: hidden;
    .slick-prev,
    .slick-next {
        z-index: 2;
    }
}