// Homepage
#banner {
    position: relative;
    z-index: 2;
    height: 70vh;
    overflow: hidden;
    @media only screen and (min-width: 992px) {
        height: 100vh;
        margin-bottom: 30px;
    }
    .banner-title {
        position: absolute;
        z-index: 2;
        top: 30px;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        img {
            width: 300px;
            margin: 0 auto;
            @media only screen and (min-width: 992px) {
                width: 500px;
            }
        }
    }
}
#homepage_slider {
    .banner-slider {
        background-size: cover;
        background-position: center;
        height: 70vh;
        @media only screen and (min-width: 992px) {
            height: 100vh;
        }
    }
}
.cursor {
    z-index: 99;
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: none;
    will-change: transform;
    margin-top: 3px;
  }

  .cursor .cursor--inner {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(239, 239, 239);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    @include respond-to('md') {
        width: 60px;
        height: 60px;
        font-size: 14px;
    }
  }
video {
    // position: absolute;
    // top: 50%;
    // left: -50%;
    height: 70vh;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    // -webkit-transform: translate(-50%,-50%);
    // transform: translate(-50%,-50%);
    z-index: 3;
    cursor: pointer;
    @media only screen and (min-width: 992px) {
        height: 100vh;
    }
}

.scroll-down-arrow {
    position: absolute;
    z-index: 2;
    bottom: 55px;
    width: 100%;
    left: 0;
    right: 0;
    img {
        cursor: pointer;
        &:hover {
            -moz-animation: bounce 2s infinite;
            -webkit-animation: bounce 2s infinite;
            animation: bounce 2s infinite;
        }
    }
}

.scroll-down-arrow-work {
    position: absolute;
    z-index: 2;
    bottom: 55px;
    width: 100%;
    left: 0;
    right: 0;
    img {
        cursor: pointer;
        &:hover {
            -moz-animation: bounce 2s infinite;
            -webkit-animation: bounce 2s infinite;
            animation: bounce 2s infinite;
        }
    }
}

.grid-img-section {
    padding-top: 60px;
    @media only screen and (min-width: 768px) {
        margin-top: -60px;
    }
    .portrait-img {
        height: 180px;
        width: 100%;
        @media only screen and (min-width: 768px) {
            height: 300px;
        }
        @media only screen and (min-width: 992px) {
            height: 360px;
        }
    }
    .landscape-img {
        height: 180px;
        width: 100%;
        @media only screen and (min-width: 992px) {
            height: 220px;
        }
    }
    .img-block {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
    .grid-img {
        padding: 15px 15px;
        @media only screen and (min-width: 992px) {
            padding: 45px 45px;
        }
        .img-wrap {
            position: relative;
            display: block;
            overflow: hidden;
            .img-fluid {
                margin: 0 auto;
            }
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background-color: $black;
                opacity: 0;
                transition: all 0.5s ease;
            }
            &:hover {
                &:before {
                    opacity: 1;
                }
                .hover-text {
                    display: block;
                }
            }
        }
        .hover-text {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            display: none;
            padding: 20px 20px;
            margin: 0;
            width: 100%;
            text-align: center;
            p {
                color: $white;
                font-size: 24px;
                font-weight: 600;
                margin-bottom: 10px;
                line-height: 1;
                font-family: Oakes Grotesk Semi Bold;
            }
            small {
                font-family: Oakes Grotesk Light;
                color: $white;
                font-size: 18px;
                line-height: 1;
            }
        }
    }
}
