// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

// @font-face {
//     font-family: 'mikelalight';
//     src: url('../fonts/mikelalight-webfont.woff2') format('woff2'),
//          url('../fonts/mikelalight-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;

// }

@font-face {
    font-family: 'Oakes Grotesk';
    src: url('../fonts/OakesGrotesk-Regular.woff2') format('woff2'),
        url('../fonts/OakesGrotesk-Regular.woff') format('woff'),
        url('../fonts/OakesGrotesk-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oakes Grotesk Semi Bold';
    src: url('../fonts/OakesGrotesk-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oakes Grotesk Bold';
    src: url('../fonts/OakesGrotesk-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oakes Grotesk Light';
    src: url('../fonts/OakesGrotesk-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}