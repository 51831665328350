/* Utilities */
.min-height-section {
    min-height: calc(100vh - 304px);
    height: calc(100% - 179px);    
    @media only screen 
    and (max-height : 500px) 
    and (min-width : 767px) 
    and (orientation : landscape) 
    { 
        height: auto;  
    }
    @media only screen and (min-device-width: 480px) 
                   and (max-device-width: 767px) 
                   and (orientation: landscape) {
                    height: auto;  
                    min-height: auto;
    } 
}

.link {
    color: $white;
    &:hover, &:focus {
        color: $white;
    }
}

.page-container {
    position: relative;
    overflow-x: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    margin-right: auto;
    margin-left: auto;
    background-color: $white;
    min-height: 100vh;
    max-width: 1600px;
}
#content {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

// Error page styling
.error-page {
    .error-page-wrap {
        padding-top: 165px;
        padding-bottom: 100px;
    }
    .scroll-top-arrow {
        padding-bottom: 30px;
    }
}

// Hero bg-image
.hero-bg-img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 100%;
    width: 100%;
}

// Video player
.video-player {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    z-index: 0;
    transform: translate(-50%,-50%);
    overflow: hidden;
    video {
        object-fit: fill;
    }
}

.mt-6{
    margin-top: 4.5rem !important;
}

.pt-100{
    padding-top: 100px;
}