// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn {
    font-size: 16px;
    font-weight: 500;
    padding: 6px 52px;
    border-radius: 0;
    &-primary {
        background-color: $black;
        border: 2px solid $black;
        &:hover, &:focus {
            background-color: transparent;
            border: 2px solid $black;
            color: $white;
            outline: none;
            box-shadow: none;
        }
    }
}

.btn-primary:focus, .btn-primary:hover, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle, .btn-primary:not(:disabled):not(.disabled):active:focus {
    background-color: transparent;
    border: 2px solid $black;
    color: $white;
    outline: none;
    box-shadow: none;
}