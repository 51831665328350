// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family

/* Font Families */
$oakes: 'Oakes Grotesk';
/* fonts to be used by the entire site */
$font-primary : $oakes;

/* Colors */
$black: #1E222C;
$white: #FFFFFF;
$gray: #f2f2f2;
$gray-dark: #1e1e1e;
$green: #5b7742;
$red: #d42943;

/* Background Colors */
$bg-color: ( 
    white: $white,
    black: $black,
    gray-dark: $gray-dark,
    red: $red,
    gray: $gray
);

/* Text Colors */
$text-color:(
    black: $black,
    white: $white,
    green: $green,
    gray: $gray
);

/* Font Weight */
$font-weight-light: 100;      /*$light and $book*/
$font-weight-normal: 400;     /*$regular*/
$font-weight-semibold: 500;   /*$semibold*/
$font-weight-bold: 700;       /*$bold*/

/// Container's maximum width
/// @type Length
$max-width: 1170px !default;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'sm': only screen and
    (
      min-width: 576px
    ),
  'md': only screen and
    (
      min-width: 768px
    ),
  'lg': only screen and
    (
      min-width: 992px
    ),
  'xl': only screen and
    (
      min-width: 1200px
    ),
  'xxl': only screen and
    (
      min-width: 1440px
    )
) !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;
