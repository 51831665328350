// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    +"Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}


@mixin respond-min($min) {
  @media screen and (min-width: $min+px) {
    @content;
  }
}

@mixin respond-min-max($min, $max) {
  @media screen and (min-width: $min+px) and (max-width: $max+px) {
    @content;
  }
}

@mixin respond-max($max) {
  @media screen and (max-width: $max+px) {
    @content;
  }
}


@mixin transition($prop: all) {
  transition: $prop 0.3s ease-in;
}

@mixin objectFit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

/* Mixin for font size */
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

/* Vendor Prefix */
@mixin prefix($property, $value) {
-webkit-#{$property}: $value;
-moz-#{$property}: $value;
-ms-#{$property}: $value;
-o-#{$property}: $value;
  #{$property}: $value;
}

// Define font size, line-height ,font weight mixin (We define `null` as the default value for each argument)
@mixin text($size: null, $lineHeight: null, $weight: null) {
  @if $size != null {
      font-size: $size;
  }

  @if $lineHeight != null {
      line-height: $lineHeight;
  }

  @if $weight != null {
      font-weight: $weight;
  }
}

// Keyframes mixin
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
      @content;
  }

  @-moz-keyframes #{$name} {
      @content;
  }

  @-ms-keyframes #{$name} {
      @content;
  }

  @keyframes #{$name} {
      @content;
  }
}


@each $bg-color, $color in $bg-color {
  .bg-#{$bg-color} {
    background: $color;
  }
}
@each $text-color, $color in $text-color {
  .text-#{$text-color} {
    color: $color;
  }
}