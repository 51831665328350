/* Studio page */
.studio-page {
    padding-top: 100px;
    .headline {
        padding: 50px 15px 100px 15px;
        @media only screen and (min-width: 992px) {
            padding: 200px 15px 250px 15px;
        }
    }
    .main-content {
        p {
            color: #f8f8f8;
        }
        hr {
            border-color: $gray;
            border-width: 2px;
            margin-top: 10px;
            margin-bottom: 30px;
        }
    }

    .text-over-content {
        padding: 40px 0 30px 0;
        @media only screen and (min-width: 992px) {
            padding: 150px 0 50px 0;
        }
        h1 {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%)
        }
        img {
            max-width: 660px;
            width: 100%;
        }
    }
}