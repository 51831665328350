/* People page */
.people-page {
    padding-top: 65px;
    .headline {
        padding: 100px 15px 100px 15px;
        @media only screen and (min-width: 768px) {
            padding: 35vh 15px 35vh 15px;
        }
        @media only screen and (min-width: 992px) {
            padding: 40vh 15px 40vh 15px;
        }
    }
    .scroll-down-arrow {
        bottom: 30px;
    }
    .main-content {
        p {
            color: #f8f8f8;
        }
        hr {
            border-color: $gray;
            border-width: 2px;
            margin-top: 10px;
            margin-bottom: 30px;
        }
    }

    .people-grid-img-section {
        padding: 50px 0 50px 0;
        @include respond-to('md') {
            padding: 200px 0 100px 0;
        }
        .img-block {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            height: 360px;
            width: 100%;
            @include respond-to('md') {
                height: 300px;
            }
            @include respond-to('lg') {
                height: 360px;
            }
        }
    
        .pgi-section {
            margin-bottom: 30px;
            @include respond-to('md') {
                padding-left: 30px;
                padding-right: 30px;
            }
            h5 {
                font-size: 16px;
                font-weight: 600;
                margin-top: 15px;
                margin-bottom: 15px;
            }
            h6 {
                font-size: 16px;
                margin-bottom: 15px;
            }
        }
    }
}