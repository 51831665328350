/**
 * Basic typography style for copy text
 */

h1, .h1 {
  @include text(52px, null, $font-weight-normal);
  @media (min-width: 768px) {
    @include text(128px, null, $font-weight-normal);
  }
  @media (min-width: 1200px) {
    @include text(180px, null, $font-weight-normal);
  }
}

h2, .h2 {
  @include text(36px, null, $font-weight-normal);
  @media (min-width: 768px) {
    @include text(58px, null, $font-weight-normal);
  }
}

h3, .h3 {
  @include text(22px, normal, $font-weight-normal);
  letter-spacing: 1px;
  @media (min-width: 768px) {
    @include text(30px, normal, $font-weight-normal);
  }
  @media (min-width: 1200px) {
    @include text(42px, normal, $font-weight-normal);
  }
}

h4, .h4 {
  @include text(22px, null, $font-weight-normal);
  @media (min-width: 768px) {
    @include text(24px, null, $font-weight-normal);
  }
  @media (min-width: 1200px) {
    @include text(32px, null, $font-weight-normal);
  }
}

h5, .h5 {
  @include text(18px, null, $font-weight-normal);
  @media (min-width: 768px) {
    @include text(28px, null, $font-weight-normal);
  }
}

h6, .h6 {
  @include text(16px, null, $font-weight-normal);
  @media (min-width: 768px) {
    @include text(24px, null, $font-weight-normal);
    letter-spacing: 0.66px;
  }
}

p {
  @include text(14px, null, $font-weight-normal);
}

a {
  &:hover, &:focus {
    outline: none;
    text-decoration: none;
  }
}

.font-oakes-grotesk { 
  font-family: 'Oakes Grotesk';
}


.font-oakes-grotesk-semi-bold{
  font-family: 'Oakes Grotesk Semi Bold';
}

.font-oakes-grotesk-light{
  font-family: 'Oakes Grotesk Light';
}

