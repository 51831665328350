// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.home{
    @media only screen and (min-width: 767px) {
        header{
            position: static;
        }
        }
    #home-header {
        @include respond-to('md') {
            position: fixed;
        }
        img{
            margin: 0 auto;
        }
    }
}

header {
    font-family: Oakes Grotesk Light;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    padding: 12px 15px;
    height: 72px;
    @include respond-to('md') {

    }
    .hover_underline{
        &:hover {
            text-decoration: underline;
        }
    }
    .hover_opacity{
        &:hover {
            opacity: 0.5;
        }
    }

    .navbar-toggler.collapsed {
        position: static!important;
        margin-top: 16px;
        @include respond-to('md') {
            position: relative!important;
            top: 11px;
            margin-top: 0px;
        }
    }

    .navbar-toggler {
        position: fixed!important;
        top: 26px;
        right: 30px;
        visibility: hidden;
        @include respond-to('md') {
            position: relative !important;
            margin-top: 0px;
            top: 10px;
            right: 20px;
        }
    }

    .header-logo {
        position: absolute;
        font-size: 32px;
        font-weight: 600;
        left: 50%;
        top: 50%;
        z-index: 1;
        transform: translate(-50%,0%);
    }

    .logo-title {
        text-align: center;
        padding-top: 8px;

        img {
            width: 180px;
            margin: 0 auto;
        }
        a{
            display: block;
            width: 180px;
            margin: 0 auto;
        }
    }
    
    &#home-header {
        background-color: white;
        .widget {
            text-align: center;
            color: white;
            list-style: none;
            padding-left: 20px;
            padding-right: 20px;
            a {
                color: white;
            }
        }
    }

    .navbar-toggler {
        cursor: pointer;
        width: 22px;
        height: 18px;
        border: none;
        padding: 0;
        z-index: 6;
        position: relative;
        @include respond-to('md') {
            width: 30px;
            height: 25px;
        }
       
        &:focus {
            border: none;
            outline: none;
        }
        &.collapsed {
            i {
                background-color: $black;
                &:nth-child(1) {
                    -webkit-animation: outT 0.8s backwards;
                    animation: outT 0.8s backwards;
                    -webkit-animation-direction: reverse;
                    animation-direction: reverse;
                }
                &:nth-child(2) {
                    margin: 5px 0;
                    -webkit-animation: outM 0.8s backwards;
                    animation: outM 0.8s backwards;
                    -webkit-animation-direction: reverse;
                    animation-direction: reverse;
                }
                &:nth-child(3) {
                    -webkit-animation: outBtm 0.8s backwards;
                    animation: outBtm 0.8s backwards;
                    -webkit-animation-direction: reverse;
                    animation-direction: reverse;
                }
            }
        }
        i {
            background-color: $black;
            border-radius: 2px;
            content: "";
            display: block;
            width: 100%;
            height: 3px;
            &:nth-child(1) {
                -webkit-animation: inT 0.8s forwards;
                animation: inT 0.8s forwards;
            }
            &:nth-child(2) {
                -webkit-animation: inM 0.8s forwards;
                animation: inM 0.8s forwards;
            }
            &:nth-child(3) {
                -webkit-animation: inBtm 0.8s forwards;
                animation: inBtm 0.8s forwards;
            }
        }
    }

    #collapsibleNavbar {
        position: fixed;
        width: 100%;
        height: calc(100% - 72px);
        background-color: $gray-dark;
        top: 72px;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 5;
        opacity: 0;
        visibility: hidden;
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 35px;
        @include respond-to('md') {
            opacity: 0;
            visibility: hidden;
            overflow: hidden;
        }
        &.collapse:not(.show) {
            display: block;
        }
        &.show {
            opacity: 1;
            visibility: visible;
            z-index: 2;
        }
        .navbar-wrap {
            height: 100%;
        }
        .widget {
            margin: 0 auto;
        }
        .row {
            position: relative;
        }
        .social-icons {
            @media only screen and (min-width: 768px) {
                position: absolute;
                right: 0;
                top: 0;
            }
            @media only screen and (min-width: 992px) {
                right: 30px;
            }
            @media only screen and (max-width: 767px) {
                margin: 0 auto;
            }
            .nav-item {
                .nav-link {
                    padding: 0 5px;
                }
                .fa {
                    color: $white;
                    font-size: 20px;
                    &:hover {
                        opacity: 0.6;
                    }
                    &.fa-linkedin {
                        font-size: 25px;
                    }
                    &.fa-instagram {
                        position: relative;
                        top: 5px;
                    }
                }
            }
        }
        .navbar-nav {
                padding: 65px 20px;
                text-align: center;
                height: calc(100% - 178px);
                justify-content: center;
                @include respond-to('md') {
                    height: calc(100vh - 160px);
                }
                @include respond-to('xl') {
                    height: calc(100vh - 190px);
                }
            
            a {
                font-size: 36px;
                color: $gray;
                @include respond-to('md') {
                    font-size: 30px;
                }
                @include respond-to('lg') {
                    font-size: 30px;
                }
                @include respond-to('xl') {
                    font-size: 60px;
                }
                &:hover {
                    opacity: 0.5;
                }
            }
        }
        img {
            position: relative;
            top: 4px;
            left: 0px;
            width: 167px;
        }
    }

    &.dark-header {
        background-color: $gray-dark!important;
        .custom-logo-link {
            transition: none;
            img {
                margin: 0 auto;
                width: 210px;
            }
        }
        .header-logo {
            margin-top: -8px;
            @include respond-to('md') {
                color: white;
            }
        }
        #collapsibleNavbar {
            .navbar-nav {
                a {
                    color: $gray;
                }
            }
        }
        .navbar-toggler {
            i {
                background-color: $gray;
            }
        }
    }
}

@-webkit-keyframes inM {
    50% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(45deg);
    }
  }
  @keyframes inM {
    50% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(45deg);
    }
  }
  
  @-webkit-keyframes outM {
    50% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(45deg);
    }
  }
  @keyframes outM {
    50% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(45deg);
    }
  }
  
  @-webkit-keyframes inT {
    0% {
      -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
      -webkit-transform: translateY(3px) rotate(0deg);
    }
    100% {
      -webkit-transform: translateY(3px) rotate(135deg);
    }
  }
  @keyframes inT {
    0% {
      transform: translateY(0px) rotate(0deg);
    }
    50% {
      transform: translateY(3px) rotate(0deg);
    }
    100% {
      transform: translateY(3px) rotate(135deg);
    }
  }
  
  @-webkit-keyframes outT {
    0% {
      -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
      -webkit-transform: translateY(9px) rotate(0deg);
    }
    100% {
      -webkit-transform: translateY(9px) rotate(135deg);
    }
  }
  @keyframes outT {
    0% {
      transform: translateY(0px) rotate(0deg);
    }
    50% {
      transform: translateY(9px) rotate(0deg);
    }
    100% {
      transform: translateY(9px) rotate(135deg);
    }
  }
  
  @-webkit-keyframes inBtm {
    0% {
      -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
      -webkit-transform: translateY(-3px) rotate(0deg);
    }
    100% {
      -webkit-transform: translateY(-3px) rotate(135deg);
    }
  }
  @keyframes inBtm {
    0% {
      transform: translateY(0px) rotate(0deg);
    }
    50% {
      transform: translateY(-3px) rotate(0deg);
    }
    100% {
      transform: translateY(-3px) rotate(135deg);
    }
  }
  
  @-webkit-keyframes outBtm {
    0% {
      -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
      -webkit-transform: translateY(-9px) rotate(0deg);
    }
    100% {
      -webkit-transform: translateY(-9px) rotate(135deg);
    }
  }
  @keyframes outBtm {
    0% {
      transform: translateY(0px) rotate(0deg);
    }
    50% {
      transform: translateY(-9px) rotate(0deg);
    }
    100% {
      transform: translateY(-9px) rotate(135deg);
    }
  }
