/* Product page */

.banner-img {
    height: 500px;
    width: 100%;
    position: relative;
    background-size: cover!important;
    background-position: center center!important;
    background-repeat: no-repeat!important;
    
    @include respond-to('md') {
        height: 600px;
    }
    @include respond-to('lg') {
        height: 60vh;
    }
    @include respond-to('xl') {
        height: 100vh;
    }
    .content-wrap {
        position: relative;
        z-index: 1;
    }
}
.full-vid{
    @media screen and (max-width: 480px) {
      height: 310px;
    }
}

.banner-grid-wrapper {
    position: relative;
    .floating-content {
        @include respond-to('xl') {
            position: absolute;
            bottom: 0;
            left: 0;
        }
        &.center {
            @include respond-to('xl') {
                position: absolute;
                bottom: auto;
                right: calc((100% - 1170px)/2);
                left: auto;
                transform: translate(0%,-50%);
                top: 50%;
                width: auto;
            }
        }
    }
}

section {
    padding: 35px 0;
    @include respond-to('md') {
        padding: 100px 0;
    }
    .banner-grid {
        padding: 0px 15px 15px 15px;
        @include respond-to('xl') {
            padding: 0 0 0 100px;
        }
        &.bg-t-50 {
            padding-top: 15px;
            @include respond-to('md') {
                padding-top: 100px;
            }
        }

        &.bg-b-50 {
            @include respond-to('md') {
                padding-bottom: 40px;
            }
            @include respond-to('lg') {
                padding-bottom: 100px;
            }
        }
    }
}

.main-text{
    line-height: 55px;
}