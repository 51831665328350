@charset "UTF-8";
/* Font Families */
/* fonts to be used by the entire site */
/* Colors */
/* Background Colors */
/* Text Colors */
/* Font Weight */
/*$light and $book*/
/*$regular*/
/*$semibold*/
/*$bold*/
/* Mixin for font size */
/* Vendor Prefix */
.bg-white {
  background: #FFFFFF; }

.bg-black {
  background: #1E222C; }

.bg-gray-dark {
  background: #1e1e1e; }

.bg-red {
  background: #d42943; }

.bg-gray {
  background: #f2f2f2; }

.text-black {
  color: #1E222C; }

.text-white {
  color: #FFFFFF; }

.text-green {
  color: #5b7742; }

.text-gray {
  color: #f2f2f2; }

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch; }

html, body {
  height: 100%;
  width: 100%;
  font-size: 16px;
  color: #1e1e1e; }
  html.overflow-hidden, body.overflow-hidden {
    overflow: hidden; }
    html.overflow-hidden header.dark-header .custom-logo-link, body.overflow-hidden header.dark-header .custom-logo-link {
      top: 0; }

body {
  font-family: "Oakes Grotesk";
  background-color: #FFFFFF; }

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0; }

img {
  display: block;
  height: auto;
  /* Make sure images are scaled correctly. */
  max-width: 100%;
  /* Adhere to container width. */ }

/**
 * Basic styles for links
 */
a {
  text-decoration: none;
  transition: all 0.3s ease-in; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0; }

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 22px;
  line-height: 26px;
  font-weight: 400;
  opacity: 1;
  /* Firefox */ }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 22px;
  line-height: 26px;
  font-weight: 400; }

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-size: 22px;
  line-height: 26px;
  font-weight: 400; }

@font-face {
  font-family: 'Oakes Grotesk';
  src: url("../fonts/OakesGrotesk-Regular.woff2") format("woff2"), url("../fonts/OakesGrotesk-Regular.woff") format("woff"), url("../fonts/OakesGrotesk-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Oakes Grotesk Semi Bold';
  src: url("../fonts/OakesGrotesk-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Oakes Grotesk Bold';
  src: url("../fonts/OakesGrotesk-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Oakes Grotesk Light';
  src: url("../fonts/OakesGrotesk-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

/**
 * Basic typography style for copy text
 */
h1, .h1 {
  font-size: 52px;
  font-weight: 400; }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 128px;
      font-weight: 400; } }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 180px;
      font-weight: 400; } }

h2, .h2 {
  font-size: 36px;
  font-weight: 400; }
  @media (min-width: 768px) {
    h2, .h2 {
      font-size: 58px;
      font-weight: 400; } }

h3, .h3 {
  font-size: 22px;
  line-height: normal;
  font-weight: 400;
  letter-spacing: 1px; }
  @media (min-width: 768px) {
    h3, .h3 {
      font-size: 30px;
      line-height: normal;
      font-weight: 400; } }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 42px;
      line-height: normal;
      font-weight: 400; } }

h4, .h4 {
  font-size: 22px;
  font-weight: 400; }
  @media (min-width: 768px) {
    h4, .h4 {
      font-size: 24px;
      font-weight: 400; } }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 32px;
      font-weight: 400; } }

h5, .h5 {
  font-size: 18px;
  font-weight: 400; }
  @media (min-width: 768px) {
    h5, .h5 {
      font-size: 28px;
      font-weight: 400; } }

h6, .h6 {
  font-size: 16px;
  font-weight: 400; }
  @media (min-width: 768px) {
    h6, .h6 {
      font-size: 24px;
      font-weight: 400;
      letter-spacing: 0.66px; } }

p {
  font-size: 14px;
  font-weight: 400; }

a:hover, a:focus {
  outline: none;
  text-decoration: none; }

.font-oakes-grotesk {
  font-family: 'Oakes Grotesk'; }

.font-oakes-grotesk-semi-bold {
  font-family: 'Oakes Grotesk Semi Bold'; }

.font-oakes-grotesk-light {
  font-family: 'Oakes Grotesk Light'; }

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table; }

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); } }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  60% {
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); } }

/* Utilities */
.min-height-section {
  min-height: calc(100vh - 304px);
  height: calc(100% - 179px); }
  @media only screen and (max-height: 500px) and (min-width: 767px) and (orientation: landscape) {
    .min-height-section {
      height: auto; } }
  @media only screen and (min-device-width: 480px) and (max-device-width: 767px) and (orientation: landscape) {
    .min-height-section {
      height: auto;
      min-height: auto; } }

.link {
  color: #FFFFFF; }
  .link:hover, .link:focus {
    color: #FFFFFF; }

.page-container {
  position: relative;
  overflow-x: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  margin-right: auto;
  margin-left: auto;
  background-color: #FFFFFF;
  min-height: 100vh;
  max-width: 1600px; }

#content {
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.error-page .error-page-wrap {
  padding-top: 165px;
  padding-bottom: 100px; }

.error-page .scroll-top-arrow {
  padding-bottom: 30px; }

.hero-bg-img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%; }

.video-player {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  z-index: 0;
  transform: translate(-50%, -50%);
  overflow: hidden; }
  .video-player video {
    object-fit: fill; }

.mt-6 {
  margin-top: 4.5rem !important; }

.pt-100 {
  padding-top: 100px; }

.btn {
  font-size: 16px;
  font-weight: 500;
  padding: 6px 52px;
  border-radius: 0; }
  .btn-primary {
    background-color: #1E222C;
    border: 2px solid #1E222C; }
    .btn-primary:hover, .btn-primary:focus {
      background-color: transparent;
      border: 2px solid #1E222C;
      color: #FFFFFF;
      outline: none;
      box-shadow: none; }

.btn-primary:focus, .btn-primary:hover, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle, .btn-primary:not(:disabled):not(.disabled):active:focus {
  background-color: transparent;
  border: 2px solid #1E222C;
  color: #FFFFFF;
  outline: none;
  box-shadow: none; }

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  .slick-prev:hover:before {
    color: #1E222C; }

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto; }

.slick-prev:before {
  content: '←'; }

[dir='rtl'] .slick-prev:before {
  content: '→'; }

.slick-next {
  right: -25px; }
  .slick-next:hover:before {
    color: #1E222C; }

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px; }

.slick-next:before {
  content: '→'; }

[dir='rtl'] .slick-next:before {
  content: '←'; }

/* Dots */
.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: 0;
  display: block;
  width: auto;
  padding: 0;
  list-style: none;
  z-index: 3;
  left: 47px;
  text-align: left; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 1px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
      .slick-dots li button:hover:before, .slick-dots li button:focus:before {
        opacity: 1; }
      .slick-dots li button:before {
        font-family: 'slick';
        /* font-size: 40px; */
        line-height: 20px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        width: 13px;
        height: 13px;
        content: "•";
        text-align: center;
        opacity: 1;
        color: #fff;
        border: 2px solid #fff;
        background-color: white;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  opacity: .75;
  background-color: transparent; }

.home-slider {
  overflow: hidden; }
  .home-slider .slick-prev,
  .home-slider .slick-next {
    z-index: 2; }

@media only screen and (min-width: 767px) {
  .home header {
    position: static; } }

@media (min-width: 768px) {
  .home #home-header {
    position: fixed; } }

.home #home-header img {
  margin: 0 auto; }

header {
  font-family: Oakes Grotesk Light;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  padding: 12px 15px;
  height: 72px; }
  header .hover_underline:hover {
    text-decoration: underline; }
  header .hover_opacity:hover {
    opacity: 0.5; }
  header .navbar-toggler.collapsed {
    position: static !important;
    margin-top: 16px; }
    @media (min-width: 768px) {
      header .navbar-toggler.collapsed {
        position: relative !important;
        top: 11px;
        margin-top: 0px; } }
  header .navbar-toggler {
    position: fixed !important;
    top: 26px;
    right: 30px;
    visibility: hidden; }
    @media (min-width: 768px) {
      header .navbar-toggler {
        position: relative !important;
        margin-top: 0px;
        top: 10px;
        right: 20px; } }
  header .header-logo {
    position: absolute;
    font-size: 32px;
    font-weight: 600;
    left: 50%;
    top: 50%;
    z-index: 1;
    transform: translate(-50%, 0%); }
  header .logo-title {
    text-align: center;
    padding-top: 8px; }
    header .logo-title img {
      width: 180px;
      margin: 0 auto; }
    header .logo-title a {
      display: block;
      width: 180px;
      margin: 0 auto; }
  header#home-header {
    background-color: white; }
    header#home-header .widget {
      text-align: center;
      color: white;
      list-style: none;
      padding-left: 20px;
      padding-right: 20px; }
      header#home-header .widget a {
        color: white; }
  header .navbar-toggler {
    cursor: pointer;
    width: 22px;
    height: 18px;
    border: none;
    padding: 0;
    z-index: 6;
    position: relative; }
    @media (min-width: 768px) {
      header .navbar-toggler {
        width: 30px;
        height: 25px; } }
    header .navbar-toggler:focus {
      border: none;
      outline: none; }
    header .navbar-toggler.collapsed i {
      background-color: #1E222C; }
      header .navbar-toggler.collapsed i:nth-child(1) {
        -webkit-animation: outT 0.8s backwards;
        animation: outT 0.8s backwards;
        -webkit-animation-direction: reverse;
        animation-direction: reverse; }
      header .navbar-toggler.collapsed i:nth-child(2) {
        margin: 5px 0;
        -webkit-animation: outM 0.8s backwards;
        animation: outM 0.8s backwards;
        -webkit-animation-direction: reverse;
        animation-direction: reverse; }
      header .navbar-toggler.collapsed i:nth-child(3) {
        -webkit-animation: outBtm 0.8s backwards;
        animation: outBtm 0.8s backwards;
        -webkit-animation-direction: reverse;
        animation-direction: reverse; }
    header .navbar-toggler i {
      background-color: #1E222C;
      border-radius: 2px;
      content: "";
      display: block;
      width: 100%;
      height: 3px; }
      header .navbar-toggler i:nth-child(1) {
        -webkit-animation: inT 0.8s forwards;
        animation: inT 0.8s forwards; }
      header .navbar-toggler i:nth-child(2) {
        -webkit-animation: inM 0.8s forwards;
        animation: inM 0.8s forwards; }
      header .navbar-toggler i:nth-child(3) {
        -webkit-animation: inBtm 0.8s forwards;
        animation: inBtm 0.8s forwards; }
  header #collapsibleNavbar {
    position: fixed;
    width: 100%;
    height: calc(100% - 72px);
    background-color: #1e1e1e;
    top: 72px;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    opacity: 0;
    visibility: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 35px; }
    @media (min-width: 768px) {
      header #collapsibleNavbar {
        opacity: 0;
        visibility: hidden;
        overflow: hidden; } }
    header #collapsibleNavbar.collapse:not(.show) {
      display: block; }
    header #collapsibleNavbar.show {
      opacity: 1;
      visibility: visible;
      z-index: 2; }
    header #collapsibleNavbar .navbar-wrap {
      height: 100%; }
    header #collapsibleNavbar .widget {
      margin: 0 auto; }
    header #collapsibleNavbar .row {
      position: relative; }
    @media only screen and (min-width: 768px) {
      header #collapsibleNavbar .social-icons {
        position: absolute;
        right: 0;
        top: 0; } }
    @media only screen and (min-width: 992px) {
      header #collapsibleNavbar .social-icons {
        right: 30px; } }
    @media only screen and (max-width: 767px) {
      header #collapsibleNavbar .social-icons {
        margin: 0 auto; } }
    header #collapsibleNavbar .social-icons .nav-item .nav-link {
      padding: 0 5px; }
    header #collapsibleNavbar .social-icons .nav-item .fa {
      color: #FFFFFF;
      font-size: 20px; }
      header #collapsibleNavbar .social-icons .nav-item .fa:hover {
        opacity: 0.6; }
      header #collapsibleNavbar .social-icons .nav-item .fa.fa-linkedin {
        font-size: 25px; }
      header #collapsibleNavbar .social-icons .nav-item .fa.fa-instagram {
        position: relative;
        top: 5px; }
    header #collapsibleNavbar .navbar-nav {
      padding: 65px 20px;
      text-align: center;
      height: calc(100% - 178px);
      justify-content: center; }
      @media (min-width: 768px) {
        header #collapsibleNavbar .navbar-nav {
          height: calc(100vh - 160px); } }
      @media (min-width: 1200px) {
        header #collapsibleNavbar .navbar-nav {
          height: calc(100vh - 190px); } }
      header #collapsibleNavbar .navbar-nav a {
        font-size: 36px;
        color: #f2f2f2; }
        @media (min-width: 768px) {
          header #collapsibleNavbar .navbar-nav a {
            font-size: 30px; } }
        @media (min-width: 992px) {
          header #collapsibleNavbar .navbar-nav a {
            font-size: 30px; } }
        @media (min-width: 1200px) {
          header #collapsibleNavbar .navbar-nav a {
            font-size: 60px; } }
        header #collapsibleNavbar .navbar-nav a:hover {
          opacity: 0.5; }
    header #collapsibleNavbar img {
      position: relative;
      top: 4px;
      left: 0px;
      width: 167px; }
  header.dark-header {
    background-color: #1e1e1e !important; }
    header.dark-header .custom-logo-link {
      transition: none; }
      header.dark-header .custom-logo-link img {
        margin: 0 auto;
        width: 210px; }
    header.dark-header .header-logo {
      margin-top: -8px; }
      @media (min-width: 768px) {
        header.dark-header .header-logo {
          color: white; } }
    header.dark-header #collapsibleNavbar .navbar-nav a {
      color: #f2f2f2; }
    header.dark-header .navbar-toggler i {
      background-color: #f2f2f2; }

@-webkit-keyframes inM {
  50% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(45deg); } }

@keyframes inM {
  50% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(45deg); } }

@-webkit-keyframes outM {
  50% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(45deg); } }

@keyframes outM {
  50% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(45deg); } }

@-webkit-keyframes inT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg); }
  50% {
    -webkit-transform: translateY(3px) rotate(0deg); }
  100% {
    -webkit-transform: translateY(3px) rotate(135deg); } }

@keyframes inT {
  0% {
    transform: translateY(0px) rotate(0deg); }
  50% {
    transform: translateY(3px) rotate(0deg); }
  100% {
    transform: translateY(3px) rotate(135deg); } }

@-webkit-keyframes outT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg); }
  50% {
    -webkit-transform: translateY(9px) rotate(0deg); }
  100% {
    -webkit-transform: translateY(9px) rotate(135deg); } }

@keyframes outT {
  0% {
    transform: translateY(0px) rotate(0deg); }
  50% {
    transform: translateY(9px) rotate(0deg); }
  100% {
    transform: translateY(9px) rotate(135deg); } }

@-webkit-keyframes inBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg); }
  50% {
    -webkit-transform: translateY(-3px) rotate(0deg); }
  100% {
    -webkit-transform: translateY(-3px) rotate(135deg); } }

@keyframes inBtm {
  0% {
    transform: translateY(0px) rotate(0deg); }
  50% {
    transform: translateY(-3px) rotate(0deg); }
  100% {
    transform: translateY(-3px) rotate(135deg); } }

@-webkit-keyframes outBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg); }
  50% {
    -webkit-transform: translateY(-9px) rotate(0deg); }
  100% {
    -webkit-transform: translateY(-9px) rotate(135deg); } }

@keyframes outBtm {
  0% {
    transform: translateY(0px) rotate(0deg); }
  50% {
    transform: translateY(-9px) rotate(0deg); }
  100% {
    transform: translateY(-9px) rotate(135deg); } }

footer {
  font-family: Oakes Grotesk Light;
  padding: 50px 0;
  background-color: #FFFFFF;
  list-style-type: none; }
  footer .row {
    position: relative; }
  @media only screen and (min-width: 768px) {
    footer .social-icons {
      position: absolute;
      right: 0;
      top: 0; } }
  @media only screen and (min-width: 992px) {
    footer .social-icons {
      right: 30px; } }
  @media only screen and (max-width: 767px) {
    footer .social-icons {
      margin: 0 auto; } }
  footer .social-icons .nav-item .nav-link {
    padding: 0 5px; }
  footer .social-icons .nav-item .fa {
    color: #1E222C;
    font-size: 20px; }
    footer .social-icons .nav-item .fa:hover {
      opacity: 0.6; }
    footer .social-icons .nav-item .fa.fa-linkedin {
      font-size: 25px; }
    footer .social-icons .nav-item .fa.fa-instagram {
      position: relative;
      top: 5px; }
  footer.dark-footer {
    background-color: #1e1e1e; }
    footer.dark-footer p {
      color: #f2f2f2; }
      footer.dark-footer p a {
        color: #f2f2f2; }
        footer.dark-footer p a:hover {
          text-decoration: underline; }
    footer.dark-footer .social-icons .nav-item .fa {
      color: #f2f2f2; }
  footer.light-footer p a {
    color: #1e1e1e; }
    footer.light-footer p a:hover {
      color: #1e1e1e;
      text-decoration: underline; }

.scroll-top-arrow {
  padding-top: 60px; }
  .scroll-top-arrow img {
    cursor: pointer; }
    .scroll-top-arrow img:hover {
      -moz-animation: bounce 2s infinite;
      -webkit-animation: bounce 2s infinite;
      animation: bounce 2s infinite; }
  .scroll-top-arrow .down-arrow {
    transform: rotate(-180deg); }

#banner {
  position: relative;
  z-index: 2;
  height: 70vh;
  overflow: hidden; }
  @media only screen and (min-width: 992px) {
    #banner {
      height: 100vh;
      margin-bottom: 30px; } }
  #banner .banner-title {
    position: absolute;
    z-index: 2;
    top: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center; }
    #banner .banner-title img {
      width: 300px;
      margin: 0 auto; }
      @media only screen and (min-width: 992px) {
        #banner .banner-title img {
          width: 500px; } }

#homepage_slider .banner-slider {
  background-size: cover;
  background-position: center;
  height: 70vh; }
  @media only screen and (min-width: 992px) {
    #homepage_slider .banner-slider {
      height: 100vh; } }

.cursor {
  z-index: 99;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  will-change: transform;
  margin-top: 3px; }

.cursor .cursor--inner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px; }
  @media (min-width: 768px) {
    .cursor .cursor--inner {
      width: 60px;
      height: 60px;
      font-size: 14px; } }

video {
  height: 70vh;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: 3;
  cursor: pointer; }
  @media only screen and (min-width: 992px) {
    video {
      height: 100vh; } }

.scroll-down-arrow {
  position: absolute;
  z-index: 2;
  bottom: 55px;
  width: 100%;
  left: 0;
  right: 0; }
  .scroll-down-arrow img {
    cursor: pointer; }
    .scroll-down-arrow img:hover {
      -moz-animation: bounce 2s infinite;
      -webkit-animation: bounce 2s infinite;
      animation: bounce 2s infinite; }

.scroll-down-arrow-work {
  position: absolute;
  z-index: 2;
  bottom: 55px;
  width: 100%;
  left: 0;
  right: 0; }
  .scroll-down-arrow-work img {
    cursor: pointer; }
    .scroll-down-arrow-work img:hover {
      -moz-animation: bounce 2s infinite;
      -webkit-animation: bounce 2s infinite;
      animation: bounce 2s infinite; }

.grid-img-section {
  padding-top: 60px; }
  @media only screen and (min-width: 768px) {
    .grid-img-section {
      margin-top: -60px; } }
  .grid-img-section .portrait-img {
    height: 180px;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .grid-img-section .portrait-img {
        height: 300px; } }
    @media only screen and (min-width: 992px) {
      .grid-img-section .portrait-img {
        height: 360px; } }
  .grid-img-section .landscape-img {
    height: 180px;
    width: 100%; }
    @media only screen and (min-width: 992px) {
      .grid-img-section .landscape-img {
        height: 220px; } }
  .grid-img-section .img-block {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center; }
  .grid-img-section .grid-img {
    padding: 15px 15px; }
    @media only screen and (min-width: 992px) {
      .grid-img-section .grid-img {
        padding: 45px 45px; } }
    .grid-img-section .grid-img .img-wrap {
      position: relative;
      display: block;
      overflow: hidden; }
      .grid-img-section .grid-img .img-wrap .img-fluid {
        margin: 0 auto; }
      .grid-img-section .grid-img .img-wrap:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: #1E222C;
        opacity: 0;
        transition: all 0.5s ease; }
      .grid-img-section .grid-img .img-wrap:hover:before {
        opacity: 1; }
      .grid-img-section .grid-img .img-wrap:hover .hover-text {
        display: block; }
    .grid-img-section .grid-img .hover-text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: none;
      padding: 20px 20px;
      margin: 0;
      width: 100%;
      text-align: center; }
      .grid-img-section .grid-img .hover-text p {
        color: #FFFFFF;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 10px;
        line-height: 1;
        font-family: Oakes Grotesk Semi Bold; }
      .grid-img-section .grid-img .hover-text small {
        font-family: Oakes Grotesk Light;
        color: #FFFFFF;
        font-size: 18px;
        line-height: 1; }

/* Studio page */
.studio-page {
  padding-top: 100px; }
  .studio-page .headline {
    padding: 50px 15px 100px 15px; }
    @media only screen and (min-width: 992px) {
      .studio-page .headline {
        padding: 200px 15px 250px 15px; } }
  .studio-page .main-content p {
    color: #f8f8f8; }
  .studio-page .main-content hr {
    border-color: #f2f2f2;
    border-width: 2px;
    margin-top: 10px;
    margin-bottom: 30px; }
  .studio-page .text-over-content {
    padding: 40px 0 30px 0; }
    @media only screen and (min-width: 992px) {
      .studio-page .text-over-content {
        padding: 150px 0 50px 0; } }
    .studio-page .text-over-content h1 {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .studio-page .text-over-content img {
      max-width: 660px;
      width: 100%; }

/* Contact page */
.contact-page {
  font-family: Oakes Grotesk Light;
  background-color: #1e1e1e;
  padding-top: 100px; }
  .contact-page a {
    color: #f2f2f2; }
    .contact-page a:hover {
      text-decoration: underline; }
  .contact-page .text-gray p {
    font-size: 42px;
    line-height: 52px;
    font-weight: 300; }
    .contact-page .text-gray p strong {
      font-weight: 900; }
    @media only screen and (max-width: 767px) {
      .contact-page .text-gray p {
        font-size: 24px;
        line-height: 30px; } }

.page-template-contact-php footer .col-12 {
  visibility: hidden; }

.page-template-contact-php footer .social-icons {
  position: absolute;
  top: 13px;
  left: 50%;
  right: auto;
  margin: 0 auto;
  text-align: center !important;
  transform: translate(-50%, -50%); }

/* Product page */
.banner-img {
  height: 500px;
  width: 100%;
  position: relative;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important; }
  @media (min-width: 768px) {
    .banner-img {
      height: 600px; } }
  @media (min-width: 992px) {
    .banner-img {
      height: 60vh; } }
  @media (min-width: 1200px) {
    .banner-img {
      height: 100vh; } }
  .banner-img .content-wrap {
    position: relative;
    z-index: 1; }

@media screen and (max-width: 480px) {
  .full-vid {
    height: 310px; } }

.banner-grid-wrapper {
  position: relative; }
  @media (min-width: 1200px) {
    .banner-grid-wrapper .floating-content {
      position: absolute;
      bottom: 0;
      left: 0; } }
  @media (min-width: 1200px) {
    .banner-grid-wrapper .floating-content.center {
      position: absolute;
      bottom: auto;
      right: calc((100% - 1170px)/2);
      left: auto;
      transform: translate(0%, -50%);
      top: 50%;
      width: auto; } }

section {
  padding: 35px 0; }
  @media (min-width: 768px) {
    section {
      padding: 100px 0; } }
  section .banner-grid {
    padding: 0px 15px 15px 15px; }
    @media (min-width: 1200px) {
      section .banner-grid {
        padding: 0 0 0 100px; } }
    section .banner-grid.bg-t-50 {
      padding-top: 15px; }
      @media (min-width: 768px) {
        section .banner-grid.bg-t-50 {
          padding-top: 100px; } }
    @media (min-width: 768px) {
      section .banner-grid.bg-b-50 {
        padding-bottom: 40px; } }
    @media (min-width: 992px) {
      section .banner-grid.bg-b-50 {
        padding-bottom: 100px; } }

.main-text {
  line-height: 55px; }

/* People page */
.people-page {
  padding-top: 65px; }
  .people-page .headline {
    padding: 100px 15px 100px 15px; }
    @media only screen and (min-width: 768px) {
      .people-page .headline {
        padding: 35vh 15px 35vh 15px; } }
    @media only screen and (min-width: 992px) {
      .people-page .headline {
        padding: 40vh 15px 40vh 15px; } }
  .people-page .scroll-down-arrow {
    bottom: 30px; }
  .people-page .main-content p {
    color: #f8f8f8; }
  .people-page .main-content hr {
    border-color: #f2f2f2;
    border-width: 2px;
    margin-top: 10px;
    margin-bottom: 30px; }
  .people-page .people-grid-img-section {
    padding: 50px 0 50px 0; }
    @media (min-width: 768px) {
      .people-page .people-grid-img-section {
        padding: 200px 0 100px 0; } }
    .people-page .people-grid-img-section .img-block {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      height: 360px;
      width: 100%; }
      @media (min-width: 768px) {
        .people-page .people-grid-img-section .img-block {
          height: 300px; } }
      @media (min-width: 992px) {
        .people-page .people-grid-img-section .img-block {
          height: 360px; } }
    .people-page .people-grid-img-section .pgi-section {
      margin-bottom: 30px; }
      @media (min-width: 768px) {
        .people-page .people-grid-img-section .pgi-section {
          padding-left: 30px;
          padding-right: 30px; } }
      .people-page .people-grid-img-section .pgi-section h5 {
        font-size: 16px;
        font-weight: 600;
        margin-top: 15px;
        margin-bottom: 15px; }
      .people-page .people-grid-img-section .pgi-section h6 {
        font-size: 16px;
        margin-bottom: 15px; }

/* About page */
.about-page {
  padding-top: 65px; }
  .about-page .headline {
    padding: 100px 15px 100px 15px; }
    @media only screen and (min-width: 768px) {
      .about-page .headline {
        padding: 35vh 15px 35vh 15px; } }
    @media only screen and (min-width: 992px) {
      .about-page .headline {
        padding: 40vh 15px 40vh 15px; } }
  .about-page .scroll-down-arrow {
    bottom: 30px; }
  .about-page .main-content p {
    color: #f8f8f8; }
  .about-page .main-content hr {
    border-color: #f2f2f2;
    border-width: 2px;
    margin-top: 10px;
    margin-bottom: 30px; }
  .about-page .banner-grid-wrapper {
    padding: 50px 0 50px 0; }
  @media (min-width: 768px) {
    .about-page .banner-img {
      height: 60vh; } }
  @media (min-width: 992px) {
    .about-page .banner-img {
      height: 50vh; } }
  @media (min-width: 1200px) {
    .about-page .banner-img {
      height: 75vh; } }
