/* Contact page */

.contact-page {
    font-family: Oakes Grotesk Light;
    background-color: $gray-dark;
    padding-top: 100px;
    a {
        color: $gray;
        &:hover {
            text-decoration: underline;
        }
    }
    .text-gray p{
    	font-size: 42px;
		line-height: 52px;
		font-weight: 300;
        strong {
            font-weight: 900;
        }
	    @media only screen and (max-width: 767px) {
	        font-size: 24px;
            line-height: 30px;
	    }
    }
}

.page-template-contact-php {
    footer {
        .col-12 {
            visibility: hidden;
        }
        .social-icons {
            position: absolute;
            top: 13px;
            left: 50%;
            right: auto;
            margin: 0 auto;
            text-align: center!important;
            transform: translate(-50%,-50%);
        }
    }
}