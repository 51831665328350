// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
footer {
    font-family: Oakes Grotesk Light;
    padding: 50px 0;
    background-color: $white;
    list-style-type: none;
    .row {
        position: relative;
    }
    .social-icons {
        @media only screen and (min-width: 768px) {
            position: absolute;
            right: 0;
            top: 0;
        }
        @media only screen and (min-width: 992px) {
            right: 30px;
        }
        @media only screen and (max-width: 767px) {
            margin: 0 auto;
        }
        .nav-item {
            .nav-link {
                padding: 0 5px;
            }
            .fa {
                color: $black;
                font-size: 20px;
                &:hover {
                    opacity: 0.6;
                }
                &.fa-linkedin {
                    font-size: 25px;
                }
                &.fa-instagram {
                    position: relative;
                    top: 5px;
                }
            }
        }
    }
    &.dark-footer {
        background-color: $gray-dark;
        p {
            color: $gray;
            a{
                color: $gray;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .social-icons {
            .nav-item {
                .fa {
                    color: $gray;
                }
            }
        }
    }

    &.light-footer{
        p {
            a{
            color: #1e1e1e;
                &:hover {
                    color: #1e1e1e;
                    text-decoration: underline;
                }
            }
        }
    }
}

.scroll-top-arrow {
    padding-top: 60px;
    img {
        cursor: pointer;
        &:hover {
            -moz-animation: bounce 2s infinite;
            -webkit-animation: bounce 2s infinite;
            animation: bounce 2s infinite;
        }
    }
    .down-arrow {
        transform: rotate(-180deg)
    }
}