/* About page */
.about-page {
    padding-top: 65px;
    .headline {
        padding: 100px 15px 100px 15px;
        @media only screen and (min-width: 768px) {
            padding: 35vh 15px 35vh 15px;
        }
        @media only screen and (min-width: 992px) {
            padding: 40vh 15px 40vh 15px;
        }
    }
    .scroll-down-arrow {
        bottom: 30px;
    }
    .main-content {
        p {
            color: #f8f8f8;
        }
        hr {
            border-color: $gray;
            border-width: 2px;
            margin-top: 10px;
            margin-bottom: 30px;
        }
    }

    .banner-grid-wrapper {
        padding: 50px 0 50px 0;
    }
    .banner-img {
        @include respond-to('md') {
            height: 60vh;
        }
        @include respond-to('lg') {
            height: 50vh;
        }
        @include respond-to('xl') {
            height: 75vh;
        }
    }
}